<template>
  <div>
    <v-card>
      <v-card-title>⚡ Wkrótce uzyskasz dostęp do statystyk</v-card-title>
      <v-card-subtitle
        >Wystarczy, że zrobisz jeszcze kilka ćwiczeń!</v-card-subtitle
      >
      <v-card-text
        >Po wykonaniu dwóch ćwiczeń, uzyskasz dostęp do statystyk dotyczących
        Twojego postępu, w tym wykresów, średnich i tym podobnych. Wróć tutaj
        wkrótce!</v-card-text
      >
    </v-card>
    <span class="caption">Jeżeli uważasz, że to błąd, wróć tu później.</span>
    <v-card class="mt-4 color-card" elevation="0" dark>
      <v-card-text>Zaraz przekierujemy Cię do listy zadań.</v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$router.replace({ name: "Dashboard" });
    }, 5000);
  },
};
</script>

<style>
.color-card {
  background: linear-gradient(
    90deg,
    rgba(72, 0, 254, 1) 0%,
    rgba(131, 58, 180, 1) 100%
  );
  color: white;
}
</style>